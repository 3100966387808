<template>
  <!-- 是否同意认购daot -->
  <el-dialog v-model="_show" :append-to-body="true" :close-on-click-modal="false">
    <div class="modal-con">
      <div class="close"></div>
      <div class="modal-title">{{ $t("daot.desc46") }}</div>
      <div class="modal-tips">{{ $t("daot.desc47") }}</div>
      <div class="modal-tips">{{ $t("daot.desc48") }}</div>
      <div class="modal-tips">{{ $t("daot.desc49") }}</div>
      <div class="modal-tips">{{ $t("daot.desc50") }}</div>
      <div class="modal-tips">{{ $t("daot.desc51") }}</div>
      <div class="modal-btn flexcenter">
        <div @click="handleConfirm(0)">{{ $t("daot.desc52") }}</div>
        <div @click="handleConfirm(1)">{{ $t("daot.desc53") }}</div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref, computed, getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  show: Boolean,
});
const emit = defineEmits(["update:show", "confirm"]);
const _show = computed({
  get() {
    return props.show;
  },
  set(e) {
    emit("update:show", e);
  },
});
function handleConfirm(isBuy) {
  // 是否购买，0：不购买，1：购买
  proxy.$post(proxy.URL.daot.choice, {isBuy: isBuy}).then((res) => {
    if (res.code == 0) {
      _show.value = false;
      emit("confirm", '');
    }
  });
}
</script>
<style lang="less" scoped>
.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: -60px;
    right: 0;
    width: 159px;
    height: 150px;
    background: url("../public/images/daot/daot-p-pc.png") center no-repeat;
    background-size: 100% 100%;
  }

  .modal-title {
    margin-bottom: 32px;
    color: #C9FA5B;
    font-family: "Bai Jamjuree";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .modal-tips {
    color: rgba(255, 255, 255, 0.80);
    font-family: "Bai Jamjuree";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;
    display: flex;
    gap: 32px;
    div {
      flex: 1;
      height: 50px;
      background: url("../public/images/daot/cancel-bt.png") center no-repeat;
      background-size: 100% 100%;
      color: #FFF;
      text-align: center;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 50px;
      text-transform: uppercase;
      cursor: pointer;
      &:last-child {
        color: #000;
        background: url("../public/images/daot/query-bt.png") center no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
@media (max-width: 1200px) {
  .modal-con {
    width: 100% !important;
    padding: 20px;

    .modal-title {
      max-width: 200px;
      margin-bottom: 20px;
      font-size: 20px;
    }
    .modal-tips {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .close {
      position: absolute;
      top: -23.5px;
      right: 0;
      width: 95px;
      height: 90px;
      background: url("../public/images/daot/daot-p.png") center no-repeat;
      background-size: 100% 100%;
    }

    .modal-btn {
      margin-top: 20px;
      display: block;
      gap: 0;
      div {
        flex: none;
        width: 100%;
        background: url("../public/images/daot/cancel-bt-h5.png") center no-repeat;
        background-size: 100% 100%;
        &:last-child {
          margin-top: 16px;
          background: url("../public/images/daot/query-bt-h5.png") center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>